<div class="input">
  <label class="input__label" for="customInputId">{{ label }}</label>
  <input class="input__select" type="text"
         [autocomplete]="autocomplete"
         [placeholder]="placeholder"
         [formControl]="formControl"
         (input)="onInputChange($event)"
         (focus)="onFocus()"
         (blur)="onBlur()"
         id="customInputId" />
</div>

{{validate | json}}
