import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { JsonPipe } from '@angular/common';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl, FormsModule, NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ReactiveFormsModule,
  ValidationErrors,
  Validator
} from '@angular/forms';

@Component({
  selector: 'ninja-textbox',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, JsonPipe],
  templateUrl: './textbox.component.html',
  styleUrl: './textbox.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextboxComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TextboxComponent),
      multi: true,
    },
  ],
})
export class TextboxComponent implements ControlValueAccessor, OnInit, Validator {
  value: string = '';

  hasErrors: string | null = null;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() autocomplete!: string;
  private onTouched: () => void = () => {
  };
  formControl: FormControl = new FormControl(); // Accept formControl as input

  @Input() errors: { [key: string]: any } = {};
  @Output() focus: EventEmitter<any> = new EventEmitter<any>();
  @Output() blur: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {

  }


  private setError(): void {
  }

  writeValue(value: any): void {
    this.value = value || '';
    this.formControl.setValue(value);
  }


  registerOnChange(fn: (value: any) => void): void {
    this.formControl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    //isDisabled ? this.form.get(this.controlName)?.disable() : this.form.get(this.controlName)?.enable();
  }

  onInputChange(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    this.formControl.setValue(inputValue);
    this.onTouched();
  }

  onFocus() {
    this.focus.emit();
  }

  onBlur() {
    this.blur.emit();
    this.onTouched();
  }

  validate(control: AbstractControl): ValidationErrors | null {

    if (!control.value || control.value.trim() === '') {
      return {required: true};
    }

    if (control.value.length < 10) {
      return {maxLengthExceeded: true};
    }

    return {maxLengthExceeded: true};
  }
}
